import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { animateScroll } from "react-scroll";
import { IoLogoInstagram, IoLogoLinkedin } from "react-icons/io";
import { MdExpandLess } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import styles from "./Footer.module.scss";
import logo from "../../../assets/images/logo/rentmanager_logo.svg";
import logoDark from "../../../assets/images/logo/v2.png";

const Footer = ({ footerBg }) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <footer
      className={`footer-area ${footerBg === "white" ? styles.footer2 : ""}`}
    >
      <div
        className={`${styles.footerTop} ${
          footerBg === "white" ? "bg--cart-7" : "bg--cart-2"
        }`}
      >
        <div className="container">
          <div className="row">
            {/* Start Single Wedget */}
            <div className="col-12 col-sm-6 col-lg-3">
              <div className={styles.singleWidget}>
                <div className={styles.logo}>
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    <img
                      src={footerBg === "white" ? logoDark : logo}
                      alt="voip"
                      width="100%"
                    />
                  </Link>
                </div>
                <div className={styles.content}>
                  <p>
                    Une solution unique pour répondre aux besoins des loueurs de véhicules de luxe.
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Wedget */}
            {/* Start Single Wedget */}
            <div className="col-12 col-sm-6 col-lg-2 offset-lg-1 xs__mt--40">
              <div className={styles.singleWidget}>
                <h2 className={styles.ftTitle}>Site</h2>
                <ul className={styles.ftContactLink}>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/#site"}>
                      Votre site internet
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/#service"}>
                      Votre panel dédié
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/#pricing"}>
                      Tarification
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* End Single Wedget */}
            {/* Start Single Wedget */}
            <div className="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12 sm__mt--40 md__mt--40">
              <div className={styles.singleWidget}>
                <h2 className={styles.ftTitle}>Infos</h2>
                <ul className={styles.ftContactLink}>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>Mentions légales</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      CGU
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* End Single Wedget */}
            {/* Start Single Wedget */}
            <div className="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12 sm__mt--40 md__mt--40">
              <div className={styles.singleWidget}>
                <h2 className={styles.ftTitle}>Blog</h2>
                <ul className={styles.ftContactLink}>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Tout les articles
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Lancer son site
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>Découvrir le panel</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* End Single Wedget */}
          </div>
        </div>
      </div>
      <div
        className={`${styles.copyright}  ${
          footerBg === "white" ? "bg--cart-8" : "bg--cart-3"
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-6">
              <div className={styles.copyrightInner}>
                <p className="copyright-text">
                  &copy; Rent-manager {new Date().getFullYear()} Made with <FaHeart />{" "}
                  in France
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className={styles.ftSocialLink}>
                <ul className={styles.socialIcon}>
                  <li>
                    <a target="_blank" href={"https://www.instagram.com/rentmanagerfr/"} rel="noopener noreferrer">
                      <IoLogoInstagram />
                    </a>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      <IoLogoLinkedin />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <MdExpandLess />
      </button>
    </footer>
  );
};

Footer.propTypes = {
  footerBg: PropTypes.string
};

export default Footer;
