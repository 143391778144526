import React from "react";
import Swiper from "react-id-swiper";
import sliderData from "../../../data/hero-sliders/hero-slider-one.json";
import HeroSliderOneSingle from "../../../components/hero-sliders/hero-slider-one";
import styles from "./HeroSliderOne.module.scss";

const HeroSliderOne = () => {
  const params = {
    effect: "fade",
    loop: false,
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    watchSlidesVisibility: false,
  };
  return (
    <div className={`hero-slider ${styles.heroSlider} ${styles.sliderOne}`}>
      <div className="slideActivation">
        <Swiper {...params}>
          {sliderData &&
            sliderData.map((single, key) => {
              return (
                <HeroSliderOneSingle
                  data={single}
                  key={key}
                  styles={styles}
                  sliderClassName="swiper-slide"
                />
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

export default HeroSliderOne;
