import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as IconPayment } from '../../UI/svg-icons/IconPayment.svg';
import { ReactComponent as IconWebsite } from '../../UI/svg-icons/IconWebsite.svg';
import { ReactComponent as IconAdmin } from '../../UI/svg-icons/IconAdmin.svg';

const ServiceOneSingle = ({ data, styles }) => {
  return (
    <div className="col-12 col-md-6 col-lg-4 md__mt--40 sm__mt--40">
      <div className={styles.service}>
        {data.id === "1" &&
          <IconPayment height="85px"/>
        }
        {data.id === "2" &&
          <IconWebsite height="85px"/>
        }
        {data.id === "3" &&
          <IconAdmin height="85px"/>
        }
        <h2>{data.title}</h2>
        <p>{data.text}</p>
        <a href={data.anchor} className="stretched-link">{data.cta}</a>
      </div>
    </div>
  );
};

ServiceOneSingle.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default ServiceOneSingle;