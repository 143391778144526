import PropTypes from "prop-types";
import React from "react";
import Button from "../../UI/button";

const PricingTableOneSingle = ({ data, styles }) => {
  return (
    <div className="col-12 col-sm-6 col-lg-4">
      <div className={`${styles.pricing_heading} ${data.featured ? styles.active : ""}`}>
          <h3>{data.planName}</h3>
          <div className={styles.price}>
            <p>
              {data.planPrice}{"€ "}
              <sub>{data.planPriceDuration}</sub>
            </p>
          </div>
      </div>
      <div
        className={`${styles.pricing}`}
      >
        <div className={styles.content}>

          <div dangerouslySetInnerHTML={{ __html: data.planFeatures }} />
          <div className="price__btn">
            <Button
              type="link"
              url="/free-trial"
              text="Essai gratuit"
              btnStyle={data.featured ? "" : "gradient"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PricingTableOneSingle.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default PricingTableOneSingle;
