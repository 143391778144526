import React, { useState } from 'react'
import axios from 'axios';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import styles from "./DemoForm.module.scss";
import Button from "../../../components/UI/button";
import contactThumb from "../../../assets/images/about/contact.png";

const DemoForm = () => {
  const [company, setcompany] = useState(null)
  const [email, setEmail] = useState(null)
  const [phone, setPhone] = useState(null)

  const askDemo = (e) =>  {
    e.preventDefault()
    const data = {
      content: "Demande de demo: Entreprise: <" + company + "> mail: <" + email + "> téléphone: <" + phone + ">"
    }
    console.log(data)
    axios.post('https://discord.com/api/webhooks/879647072171745311/OumdoGsj9nNWnZIA0ZJGyPyfbRxVy3_0-kksZdJGTb_QEwsacGagXXbHLDDuwswMqA9j', data).then((res) => {
      toast.success('Demande envoyé avec succès!')
      setcompany(null)
      setEmail(null)
      setPhone(null)
      setTimeout(function(){
        window.location.href = "/";
      }, 3000);
    })
  }

  return (
    <div className="rm__contact ptb--110">
      <div>
        <Toaster
          position="bottom-center"
          reverseOrder={false}
      /></div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className={styles.rmContactForm}>
              <h2>
                Remplissez le formulaire pour bénéficier d'un mois gratuit !
              </h2>
                <div className={styles.singleContactForm}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Nom de votre entreprise"
                    onChange={(e) => {
                      setcompany(e.target.value)
                    }}
                  />
                </div>
                <div className={styles.singleContactForm}>
                  <input
                    type="email"
                    name="email"
                    placeholder="Votre adresse mail"
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                  />
                </div>
                <div className={styles.singleContactForm}>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Numéro de téléphone"
                    onChange={(e) => {
                      setPhone(e.target.value)
                    }}
                  />
                </div>
                <br></br>
                <div className={styles.contactBtn} onClick={(e) => askDemo(e)}>
                  <Button
                    type="button"
                    text="Demander mon essai gratuit"
                  />
                </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
            <div className={styles.contactThumb}>
              <img src={contactThumb} alt="rm voip" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoForm;
