import React from "react";
import styles from "./SoftwareDownload.module.scss";
import softwareContent from "../../../data/software-download/software-download-one.json";
// import polyOne from "../../../assets/images/icons/pl1.png";
// import polyTwo from "../../../assets/images/icons/pl2.png";
import softImage from "../../../assets/images/banner/s3.png";
import Button from "../../../components/UI/button";

const SoftwareDownloadOne = () => {
  return (
    <div className={`${styles.rmSoftware} poss--relative bg--white`}>
      <div className={`${styles.sftBgColor} poss--relative`}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7 col-lg-6">
              <div className={styles.content}>
                <h2>{softwareContent.title}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: softwareContent.content }}
                />
              </div>
              <div className={styles.contactBtn}>
                {/* eslint-disable-next-line */}
                  <Button type="button" type="link" url="/free-trial" text="Demander une démonstration"  btnStyle="white"/>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.poly1}>
          <img src={polyOne} alt="rm voip" />
        </div>
        <div className={styles.poly2}>
          <img src={polyTwo} alt="rm voip" />
        </div> */}
      </div>
      <div className={styles.shape1}>
        <img src={softImage} alt="rm voip" />
      </div>
    </div>
  );
};

export default SoftwareDownloadOne;
