import React from "react";
import styles from "./Navigation.module.scss";
import { HashLink as Link } from 'react-router-hash-link';

const Navigation = () => {
  return (
    <nav>
      <ul className={styles.mainMenu}>
        <li className={styles.drop}>
          <Link to={process.env.PUBLIC_URL + "/"}>
            Accueil
          </Link>
        </li>
        <li className={styles.drop}>
          <Link to={process.env.PUBLIC_URL + "/#service"}>
            Service
          </Link>
        </li>
        <li className={styles.drop}>
          <Link to={process.env.PUBLIC_URL + "/#pricing"}>
            Tarifs
          </Link>
        </li>
        <li className={styles.drop}>
          <Link to={process.env.PUBLIC_URL}>
            Blog
          </Link>
        </li>
        <li className={styles.drop}>
          <Link to={process.env.PUBLIC_URL + "/contact"}>
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
