import React, {useState} from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import styles from "./ContactForm.module.scss";
import Button from "../../../components/UI/button";
import contactThumb from "../../../assets/images/about/contact.png";

const ContactForm = () => {
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [message, setMessage] = useState(null)
  const [phone, setPhone] = useState(null)

  const contact = (e) =>  {
    e.preventDefault()
    const data = {
      content: "CONTACT: Prénom: <" + name + "> Phone: <" + phone + "> Mail: <" + email + "> Message: <" + message + ">"
    }
    console.log(data)
    axios.post('https://discord.com/api/webhooks/879647072171745311/OumdoGsj9nNWnZIA0ZJGyPyfbRxVy3_0-kksZdJGTb_QEwsacGagXXbHLDDuwswMqA9j', data).then((res) => {
      toast.success('Demande envoyé avec succès!')
      setName(null)
      setEmail(null)
      setMessage(null)
      setTimeout(function(){
        window.location.href = "/";
      }, 3000);
    })
  }

  return (
    <div className="rm__contact ptb--110">
      <div>
        <Toaster
          position="bottom-center"
          reverseOrder={false}
      />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className={styles.rmContactForm}>
              <h2>
                Une question ? Un problème ?
              </h2>
              <form>
                <div className={styles.singleContactForm}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Prénom"
                    onChange={(e) => {
                      setName(e.target.value)
                    }}
                  />
                </div>
                <div className={styles.singleContactForm}>
                  <input
                    type="email"
                    name="email"
                    placeholder="Adresse mail"
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                  />
                </div>
                <div className={styles.singleContactForm}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Numéro de téléphone"
                    onChange={(e) => {
                      setPhone(e.target.value)
                    }}
                  />
                </div>
                <div className={`${styles.singleContactForm} message`}>
                  <textarea
                    name="message"
                    placeholder="Votre message"
                    defaultValue={""}
                    onChange={(e) => {
                      setMessage(e.target.value)
                    }}
                  />
                </div>
                <div className={styles.contactBtn} onClick={(e) => contact(e)}>
                  <Button type="button" text="Envoyer" />
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
            <div className={styles.contactThumb}>
              <img src={contactThumb} alt="rm voip" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
