import PropTypes from "prop-types";
import React from "react";
import Button from "../../UI/button";
import { useParallax } from 'react-scroll-parallax';
// import { ReactComponent as IconInstagram } from '../../UI/svg-icons/IconInstagram.svg';
// import { ReactComponent as IconLinkedin } from '../../UI/svg-icons/IconLinkedin.svg';
// import { ReactComponent as IconFacebook } from '../../UI/svg-icons/IconFacebook.svg';


const HeroSliderOneSingle = ({ data, styles, sliderClassName }) => {
  const { ref } = useParallax({ 
    speed: 9,
    translateX: [25,-25]
   });
  return (
    <div
      className={`${styles.slide} ${styles.sliderFixedHeight} ${
        styles.graBg1
      } ${sliderClassName ? sliderClassName : ""} d-flex align-items-center position-relative`}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <div className={`${styles.content}`}>
              <h1>{data.title}</h1>
              <p className="w-100">{data.text}</p>
              <Button type="link" url={"/free-trial"} text="Essayez 1 mois !" btnStyle="white"/>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div ref={ref} 
                 className={styles.slideFrontImg}>
              <img
                src={require("./../../../assets/images/banner/" + data.image)}
                alt="rm voip hero_img"
              />
            </div>
          </div>
        </div>
        <div className={`${styles.socialMedia}`}>
          <div className="container">
            {/* <a href="https://www.instagram.com/rentmanagerfr/">
              <IconInstagram height="35px" class="mr-3"/>
            </a>
            <a href="https://www.rent-manager.fr/#/">
              <IconLinkedin height="35px" class="mr-3"/>
            </a>
            <a href="#insta">
              <IconFacebook height="35px" class="mr-3"/>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

HeroSliderOneSingle.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default HeroSliderOneSingle;
