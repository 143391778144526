import React from "react";
import Button from "../../../components/UI/button";
import styles from "./FeatureImageTextTwo.module.scss";
import featureData from "../../../data/features/feature-two.json";
import { useParallax } from 'react-scroll-parallax';

const FeatureImageTextTwo = () => {
  const { ref } = useParallax({ 
    speed: 2,
    translateX: [50,0],
    scaleY: [1, 1.2],
    opacity: [1, 0.8]
   });
  return (
    <div className={`${styles.rmBusiness} bg--white ptb--110`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 col-xl-5 sm__mt--40">
            <div className={styles.content}>
              <h2>{featureData.title}</h2>
              <p className={styles.firstDesc}>{featureData.text}</p>
              <div className="mb-5" dangerouslySetInnerHTML={{ __html: featureData.content }} />
              <Button type="link" url={"/free-trial"} text="Commencer mon essai gratuit" btnStyle="gradient"/>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-7">
            <div className={styles.thumb}>
              <img
                ref={ref}
                src={require("./../../../assets/images/about/" +
                  featureData.image)}
                alt="rm voip"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureImageTextTwo;
