import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import { ParallaxProvider } from 'react-scroll-parallax';
import HeroSliderOne from "../containers/hero-sliders/hero-slider-one";
import ServiceOne from "../containers/services/service-one";
import FeatureImageTextOne from "../containers/feature-image-texts/feature-image-text-one";
import FeatureImageTextTwo from "../containers/feature-image-texts/feature-image-text-two";
import ServiceTwo from "../containers/services/service-two";
import SoftwareDownloadOne from "../containers/software-downloads/software-download-one";
import PricingTableOne from "../containers/pricing-tables/pricing-table-one";
import ReactGA from 'react-ga';
ReactGA.initialize('G-GHNRJB4MJS');
ReactGA.pageview(window.location.pathname + window.location.search);

const HomeOne = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Rent-manager | Le logiciel pour la gestion de véhicules de luxe</title>
        <meta
          name="description"
          content="Rent-Manager - Le logiciel dédié aux agences de location de véhicules de luxe. Créez votre site en quelques minutes, gérez vos clients, vos véhicules et tout les aspects de votre métier."
        />
        <meta
          property="og:image"
          content="https://www.rent-manager.fr/static/media/s3.2de6bdf5.png"
        />
      </MetaTags>
      <LayoutOne>
        {/* contains parallaxed elements */}
        <ParallaxProvider> 
          {/* hero slider */}
          <HeroSliderOne />
          {/* service */}
          <ServiceOne />
          {/* feature 1 */}
          <div id= "payment">
            <FeatureImageTextOne />
          </div>
          {/* feature 2 */}
          <div id="website">
            <FeatureImageTextTwo />
          </div>
          {/* feature 3 */}
          <div id="admin"></div>
          {/* service with text */}
          <div id= "service">
            <ServiceTwo />
          </div>
          {/* software download */}
          <SoftwareDownloadOne />
          {/* pricing table */}
          <div id= "pricing">
            <PricingTableOne />
          </div>
          {/* blog grid
          <BlogGrid />*/}
        </ParallaxProvider>
      </LayoutOne>
    </Fragment>
  );
};

export default HomeOne;
