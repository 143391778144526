import PropTypes from "prop-types";
import React from "react";

const ServiceTwoSingle = ({ data, styles }) => {
  return (
    <div className="col-12 col-lg-12 col-xl-6 sm__mt--40">
      <div className={styles.bestService}>
        <div
          className={styles.icon}
          style={{
            background: `url(${require("./../../../assets/images/icons/feather/" +
              data.icon)}) no-repeat scroll center 18px`,
            backgroundSize: `auto 25%`,
            height: '150px'
          }}
        >
        </div>
        <div className={styles.content}>
          <h3>{data.title}</h3>
          <p>{data.text}</p>
        </div>
      </div>
    </div>
  );
};

ServiceTwoSingle.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default ServiceTwoSingle;
